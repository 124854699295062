.Content {
  padding: 0 var(--Spacer);

  @media (--screen-1200) {
    lost-column: 5/7 0 0;
    lost-move: 2/7;
  }

  h2:after {
    background: var(--brandColor);
    content: '';
    display: block;
    height: 7px;
    margin: calc(var(--Spacer) * 0.2) 0 var(--Spacer);
    width: 100%;
  }

}

.pageContent {
  margin-bottom: calc(var(--Spacer) * 3);

  > div {
    margin-top: calc(var(--Spacer) *3);
  }

  .boxBorder {
    margin: 0;
  }

  .Profile img {
    width: 100%;

    @media (--screen-600) {
      float: right;
      margin: var(--Spacer);
      width: 40%;
    }
  }
}



.boxContentPage {
  clear: both;

  + .boxContentPage {
    margin-top: calc(var(--Spacer) * 2);
  }

  h6 {
    font-size: calc(var(--fontSize) * 1.3);

    /* &:after {
      background: var(--brandColor);
      content: '';
      display: inline-block;
      height: calc(var(--Spacer) * 0.2);
      margin: calc(var(--Spacer) * 0.8) var(--Spacer) 0;
      width: calc(var(--Spacer) * 6);
    } */
  }
}
