.Navi {
  background: var(--Content2);
  padding: 0 1em 1em 1em;
  position: relative;
  width: 100%;
  z-index: 100;

  @media (--screen-1366) {
    padding: 0 1em;
  }

  &.fixed {
    @media (--screen-1366) {
      opacity: 1;
      position: fixed;
      top: 0;
      z-index: 2;
      animation-name: fadeIn;
      animation-duration: 1s;
    }
  }

  .Logo {
    text-align: center;

    img {
      @media (--screen-1366) {
        width: 20em;
      }
    }
  }
}

.main-menu {
  background: var(--Content2);
  border-top: calc(var(--Spacer) * 0.6) solid var(--brandColor);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: -100px;
  display: none;
  margin-top: 135px;
  padding-bottom: 183px;

  @media (--screen-1366) {
    display: block;
    transform: translateX(8%); 
    border: none;
    height: auto;
    overflow-y: visible;
    position: static;
    width: auto;
    margin: 0;
    padding: 0;
  }

  /* :: main-menu-open :: */
  &-open {
    overflow-y: hidden;
    @media (--screen-1366) {
      overflow-y: auto;
    }
  }

  /* :: main-menu-btn :: */
  &-btn {
    cursor: pointer;
    display: block;
    height: calc(var(--Spacer) * 2);
    overflow: hidden;
    position: absolute;
    right: var(--Spacer);
    text-indent: calc(var(--Spacer) * 2);
    top: calc(var(--Spacer) * 2.5);
    white-space: nowrap;
    width: calc(var(--Spacer) * 2);
    z-index: 2;

    @media (--screen-1366) {
      display: none;
    }

    &-icon, &-icon:before, &-icon:after {
      position: absolute;
      top: 51%;
      left: 2px;
      height: 3px;
      width: 30px;
      background: var(--brandColor);
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
    }

    &-icon:before {
      content: '';
      top: -8px;
      left: 0;
    }

    &-icon:after {
      content: '';
      top: 8px;
      left: 0;
    }
  }

  /* :: main-menu-state ::*/
  &-state {
    display: none;

    & ~ {

      .Wrapper {
        transform: none;
      }
    }

    &:checked ~ {

      .Wrapper {

        .main-menu {
          top: 0;
          display: block;
        }

        @media (--screen-1366) {
          transform: none;
        }

        .main-menu {
          right: 0px\0;
        }

        .main-menu-btn-icon {
          height: 0;
          background: transparent;

          &:before {
            top: 0;
            transform: rotate(-45deg);
          }

          &:after {
            top: 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  a {
    padding: var(--Spacer);
    /* make room for the toggle button (sub indicator) */
    padding-right: calc(var(--Spacer) * 2);
    color: #555555;
    font-family: "Lucida Sans Unicode", "Lucida Sans", "Lucida Grande", Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 17px;
    text-decoration: none;

    @media (--screen-1366) {
      padding: var(--Spacer) calc(var(--Spacer) * 2);
    }

    &:hover, &:focus, &:active {
      color: #555555;
      font-family: "Lucida Sans Unicode", "Lucida Sans", "Lucida Grande", Arial, sans-serif;
      font-size: 18px;
      font-weight: normal;
      line-height: 17px;
      text-decoration: none;
    }

    &.current {
      /* color: var(--brandColor); */
    }

    &.disabled {
      color: #bbbbbb;
    }

    span:not(.sub-arrow) {
      &:hover, &:focus, &:active, &.highlighted {
        /* color: var(--Content2); */
      }
    }

    span.sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 20px;
      width: 0;
      height: 0;
      background: transparent;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid #555;
      border-right: 2px solid #555;
      transform: rotate(45deg);
    }
  }

  li {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  > li:first-child {
    border-top: 0;
  }
  ul {
    background: color(var(--ContentLight) alpha(-90%));

    a {
      border-left: 1em solid transparent;
    }

    ul a {
      border-left: 2em solid transparent;
    }

    ul ul a {
      border-left: 3em solid transparent;
    }
  }

  .Search2 {
    margin: 2em 1em 0;
    /* text-align: center; */
    @extend animate;

    @media (--screen-1366) {
      display: none;
      opacity: 0;
      position: absolute;
      right: 0;
      text-align: right;
      top: 5em;
      width: 30vw;
      z-index: 2;
    }

    &.Search-open {
      display: block;
      opacity: 1;
    }

    .txtSearch {
      width: 100%;

      @media (--screen-1366) {
        /* width: 100%; */
      }
    }

    .title,
    .btnSearch {
      display: none;
    }

    .search-button {
      cursor: pointer;
      margin: 1rem 0 1rem 1rem;
      @mixin circleBtn;
    }

  }

  .search-button {
    margin: 1rem 0 1rem 1rem;
    @mixin circleBtn;
    display: inline-block;

    @media (--screen-1366) {
      display: none;
    }



    /* @media (--screen-1366) {
      margin: 1.5rem 0 1.5rem 2rem;
    } */
  }
}

@media (--screen-1366) {
  /* Switch to desktop layout
  -----------------------------------------------
     These transform the menu tree from
     collapsible to desktop (navbar + dropdowns)
  -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */

  .main-menu {

    > li > ul > li a {

      &:before {
        background: var(--brandColor);
        content: '';
        display: block;
        left: 0;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
  
      &:hover, &:focus, &:active, &.highlighted {
        &:before {
          opacity: 1;
          transition: 0.3s;
        }
  
        .sub-arrow {
          border-color: var(--Content2);
        }
      }

      .sub-arrow {
        width: 7px;
        height: 7px;
        border-bottom: none;
        border-top: 2px solid #555;
        border-right: 2px solid #555;
        transform: rotate(45deg);
      }
    }

    ul {
      position: absolute;
      width: 12em;
    }

    li {
      float: left;
    }

    &.sm-rtl li {
      float: right;
      color: rgb(133, 34, 214);
    }

    ul li, &.sm-rtl ul li, &.sm-vertical li {
      float: none;
    }

    ul a, &.sm-vertical a {
      white-space: normal;
    }

    .sm-nowrap > li > {
      a, :not(ul) a {
        white-space: nowrap;
      }
    }

    a {
      border-bottom: 7px solid transparent;
      color: var(--Content);
      white-space: nowrap;
      transition: 0.3s;

      &:hover, &:focus, &:active, &.highlighted {
        border-color: var(--brandColor);
      }

      &.disabled {
        color: var(--ContentLight);
      }

      &.has-submenu {
        padding-right: calc(var(--Spacer) * 2);
      }

    }

    li {
      border-top: 0;
    }

    ul {
      background: var(--Content2);
      box-shadow: 4px 4px 10px #51515166;

      a {
        border: 0 !important;
        color: var(--ContentDark);
        padding: var(--Spacer);
        &:hover, &:focus, &:active, &.highlighted {
          border: 0 !important;
          color: var(--Content2);
        }
        &.current {
          /* color: var(--brandColor); */
        }
        &.disabled {
          background: white;
          color: #cccccc;
        }
        &.has-submenu {
          padding-right: 20px;
        }
        span.sub-arrow {
          /* border-width: 5px;
          border-style: dashed dashed dashed solid;
          border-color: transparent transparent transparent #555555; */  

          /* top: 50%;
          margin-top: -2px;
          right: 12px;
          width: 0;
          height: 0;
          background: transparent;

          width: 7px;
          height: 7px;
          border-top: 2px solid #555;
          border-right: 2px solid #555;
          transform: rotate(45deg); */
        }
      }
    }
    span {
      &.scroll-up, &.scroll-down {
        position: absolute;
        display: none;
        visibility: hidden;
        overflow: hidden;
        background: white;
        height: 20px;
      }
      &.scroll-up:hover, &.scroll-down:hover {
        background: #eeeeee;
      }
      &.scroll-up:hover span {
        &.scroll-up-arrow, &.scroll-down-arrow {
          border-color: transparent transparent #d23600 transparent;
        }
      }
      &.scroll-down:hover span.scroll-down-arrow {
        border-color: #d23600 transparent transparent transparent;
      }
      &.scroll-up-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -6px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 6px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #555555 transparent;
      }
      &.scroll-down-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -6px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 6px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #555555 transparent;
        top: 8px;
        border-style: solid dashed dashed dashed;
        border-color: #555555 transparent transparent transparent;
      }
    }
    &.sm-rtl {
      a {
        &.has-submenu {
          padding-right: 12px;
          padding-left: 24px;
        }
        span.sub-arrow {
          right: auto;
          left: 12px;
        }
      }
      &.sm-vertical a {
        &.has-submenu {
          padding: 10px 20px;
        }
        span.sub-arrow {
          right: auto;
          left: 8px;
          border-style: dashed solid dashed dashed;
          border-color: transparent #555555 transparent transparent;
        }
      }
      > li > ul {
        &:before {
          left: auto;
          right: 30px;
        }
        &:after {
          left: auto;
          right: 31px;
        }
      }
      ul a {
        &.has-submenu {
          padding: 10px 20px !important;
        }
        span.sub-arrow {
          right: auto;
          left: 8px;
          border-style: dashed solid dashed dashed;
          border-color: transparent #555555 transparent transparent;
        }
      }
    }
    &.sm-vertical {
      padding: 10px 0;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
      a {
        padding: var(--Spacer) calc(var(--Spacer) * 2);
        &:hover, &:focus, &:active, &.highlighted {
          background: white;
        }
        &.disabled {
          background: #eeeeee;
        }
        span.sub-arrow {
          right: 8px;
          top: 50%;
          margin-top: -5px;
          border-width: 5px;
          border-style: dashed dashed dashed solid;
          border-color: transparent transparent transparent #555555;
        }
      }
      > li > ul {
        &:before, &:after {
          display: none;
        }
      }
      ul a {
        padding: var(--Spacer) calc(var(--Spacer) * 2);
        &:hover, &:focus, &:active, &.highlighted {
          background: #eeeeee;
        }
        &.disabled {
          background: white;
        }
      }
    }
  }
  /* ...end */
}