.HotProducts {
  background: url('../images/poly.jpg') center no-repeat / cover;
  padding: calc(var(--fontSize) * 2) calc(var(--fontSize) * 1) 0;

  p {
    line-height: 1.2;
    height: calc(var(--Spacer) * 3);
    padding: calc(var(--Spacer) * 0.5);
    color: var(--Content);
    transition: 0.3s;
    &:focus, &:hover {
      @extend animate;
      color: var(--brandColor2);
    }
  }

  .slick {
    text-align: center;
    width: 80%;
    margin: 0 auto;

    &-arrow {
      background: none;
      margin: calc(var(--fontSize) * -2.7) calc(var(--fontSize) * -3);
      opacity: 1;
      z-index: 100;


      &:before {
        font-size: 2.5rem;
        color: var(--Content);
      }

      &:hover, &:active {
        &:before {
          @extend animate;
          color: var(--brandColor2);
        }
      }
    }

    &-prev {
      left: 0;
    }
  
    &-next {
      right: 0;
    }

    img {
      width: 100%;
      margin: 0 auto;
      border-top: solid 7px var(--brandColor);
      box-shadow: 4px 4px 10px #51515166;
      cursor: pointer;
    }

  }

  .HotProduct {
    padding: 10px;
    &:hover {
      p {
        color: var(--brandColor2);
      }
    }
  }
}


@media (--screen-1366) {
  .HotProducts {
    padding: calc(var(--fontSize) * 4) 0 calc(var(--fontSize) * 2);

    p {
      margin-top: 10px;
    }

    .slick {
      &-prev {
        left: -1rem;
      }
      &-next {
        right: -1rem;
      }
    }

    .HotProduct {
      padding: 20px;
    }
  }
}