@define-mixin formDefault $color: var(--Content) {
  background: var(--Content2);
  border: 3px solid color($color l(+70%));
  color: $color;
  font-family: inherit;
  padding: 1em;
  @extend animate;

  &:hover {
    border-color: color($color l(+40%));
  }

  &:focus {
    border-color: var(--brandColor);
  }
}

@define-mixin fontAwesomeIcon $pos, $color, $icon, $padding {
  &:$pos {
    color: $color;
    content: '\$icon';
    display: inline-block;
    padding: $padding;
    @extend fontAwesome;
  }
}

@define-mixin circleBtn {
  @extend circleBtn;
  @extend animate;

  &:focus, &:hover {
    background: var(--ContentLight);
  }

  .fa {
    line-height: inherit;
  }
}