body {
  font-size: calc(var(--fontSize) * 0.9);
  line-height: calc(var(--Spacer) * 2);

  @media (--screen-1366) {
    font-size: var(--fontSize);
  }
}

a, a:active, a:visited, a:hover, a:focus {
  text-decoration: none;
}

a, a:active {
  color: var(--brandColor2);
  @extend animate;
  transition-property: color;
}

a:hover, a:focus {
  color: var(--ContentLight);
}

h1, h2, h3, h4, h5, h6 {
  @extend fontDefault;
  line-height: calc(var(--Spacer) * 1.5);
  margin-bottom: calc(var(--Spacer) / 2);
}

h1, h2, h4, h5, h6 {
  color: var(--brandColor);
}

h1 { font-size: 2.5rem;  }
h2 { font-size: 2.2rem;    }
h3 { font-size: 2rem;    }
h4 { font-size: 1.5rem;  }
h5 { font-size: 1.5rem; }
h6 { font-size: 1.5rem;    }

h2 {
  font-weight: 900;
  text-align: center;
  line-height: calc(var(--Spacer) * 1.1);

  @media (--screen-1366) {
    line-height: calc(var(--Spacer) * 1.5);
  }
}

p {
  margin-bottom: calc(var(--Spacer) / 2);
}

.main-menu ul,
.Sidebar ul {
  span:not(.sub-arrow) {
    display: inline-block;
    font-size: 80%;
    padding: 0 var(--Spacer);
  }
}

.Content {
  ul {
    margin-left: calc(var(--Spacer) * 1.5);

    li {
      + li {
        margin-top: var(--Spacer);
      }
    }

    ul {
      color: var(--ContentLight);
      margin-top: var(--Spacer);

      li {
        + li {
          margin-top: calc(var(--Spacer) * 0.5);
        }
      }
    }
  }

  .listDark {
    span {
      padding-left: var(--Spacer);

      &:not(b) {
        color: var(--ContentLight);
      }
    }
  }

  .pID {
    color: var(--ContentLight);
    font-size: calc(var(--fontSize) * 0.8);
    margin: 0 var(--Spacer);
  }
}

a.txtMore {
  @extend animate;
  background: var(--brandColor2);
  border-radius: calc(var(--Spacer) * 0.5);
  color: var(--Content2);
  padding: calc(var(--Spacer) * 0.5) var(--Spacer);

  &:focus, &:hover {
    background: var(--ContentLight);
  }
}

.txtImportant {
  color: var(--brandColor2);
  font-size: var(--fontSize);
}

h4.search,
h5.search {
  color: var(--ContentLight);
  padding: 0 var(--Spacer);
}