.TopNavi {
  background: url('../images/TopNavi-bg.jpg') center center no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  z-index: 0;

  &:before {
    background: url('../images/TopNavi-cover.png') center center no-repeat;
    background-size: cover;
    content: '';
    display: block;
    opacity: 1;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
  }

  &:after {
    background: linear-gradient(to left, color(var(--brandColor) l(+35%)) 0%, #fff 35%, #fff 65%, color(var(--brandColor) l(+35%)) 100%);
    background-size: cover;
    content: '';
    display: block;
    opacity: 0.6;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
  }

  ul {
    color: var(--brandColor);
    padding: calc(var(--Spacer) * 3) var(--Spacer) calc(var(--Spacer) * 3.5);
    position: relative;

    a {
      color: var(--Content);
      font-size: calc(var(--fontSize) * 1.2);
      font-weight: 700;

      &:focus,
      &:hover {
        color: var(--brandColor2);
      }
    }

    li {
      display: inline-block;

      &:last-child {
        display: block;
        font-size: calc(var(--fontSize) * 2.2);
        font-weight: 900;
        margin-top: calc(var(--Spacer) * 0.5);

        /* a {
          color: var(--Content);

          &:focus,
          &:hover {
            color: var(--brandColorLight);
          }
        } */
      }

      + li {
        margin-left: -4px;

        &:not(:last-child) {
          /* @mixin fontAwesomeIcon
            before, , f04d, 0 var(--Spacer); */
          &:before {
            content: '';
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 2px;
            background-color: var(--brandColor);
            margin: 0 calc(var(--Spacer) * 1.8) calc(var(--Spacer) * 0.2);
          }
        }
      }
    }
  }
}