.Header {
  background: color(var(--brandColor));
  text-align: center;
  padding: calc(var(--Spacer)*0.25);

  @media (--screen-1366) {
    text-align: right;
  }

  ul {
    font-size: 0;

    li {
      display: inline-block;
      font-size: calc(var(--fontSize)*0.9);
      padding-top: 2px;
      line-height: 2;

      @media (--screen-1366) {
        margin: -5px 6px 0;
        line-height: 0;
      }
      
      + li {
        margin-left: var(--Spacer);
      }
    }

    a {
      @extend alink;
      color: var(--Content2);
      &:hover, &:focus, &:active {
        color: var(--brandColor2);
      }
    }
  }

  select {
    background: transparent;
    border: none;
    color: var(--Content2);
    padding: calc(var(--Spacer)*0.5);
    @extend fontDefault;

    option {
      color: var(--Content);
    }
  }


  .rss {

    a {
      color: #F77500;

      &:before {
        content: "\f143";
        margin-right: calc(var(--Spacer)*0.3);
        @extend fontAwesome;
      }
    }

    img {
      display: none;
    }
  }

  .Lang {

    /* a[title="facebook"]:after{
      content: '\f082';
      color: #3A5795;
      @extend fontAwesome;
    } */

    img {
      display: none;
    }
  }

  #google_translate_element {
    display: inline-block;
    margin-right: var(--fontSize);
  }
}

.Search {
  display: none;
  text-align: center;
  @extend animate;

  @media (--screen-1366) {
    display: block;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 5em;
    /* top: 50px; */
    /* width: 30vw; */
    z-index: 2;
  }

  &.Search-open {
    display: none;
    opacity: 1;
    position: relative;
    z-index: 10000;
    top: -4px;

    @media (--screen-1366) {
      display: inline-block;
    }
  }

  .txtSearch {

    @media (--screen-1366) {
      display: inline-block;
      /* float: right; */
      /* position: absolute;
      top: -100px;
      right: 0; */
    }
  }

  .title,
  .btnSearch {
    display: none;
  }
}

.search-button {
  /* margin: 1rem 0 1rem 2rem; */
  @mixin circleBtn;
  display: none;
  height: calc(var(--fontSize) * 3);
  width: calc(var(--fontSize) * 3);

  @media (--screen-1366) {
    display: inline-block;
    margin-top: 6px;
  }
}