.PageCode {
  text-align: center;

  li {
    display: inline-block;

    + li {
      margin-left: -4px;
    }

    a {
      @extend animate;
      color: var(--ContentLight);
      display: block;
      line-height: calc(var(--Spacer) * 1.5);
      margin: calc(var(--Spacer) * 0.5);
      padding: calc(var(--Spacer) * 0.5);

      &:focus,
      &:hover {
        color: var(--brandColor);
      }
    }
  }

  .prevnext {
    border: none;
    font-size: 0;

    &[title='previous'] {
      @mixin fontAwesomeIcon after, var(--Content2), f104, 0;
    }

    &[title='next'] {
      @mixin fontAwesomeIcon after, var(--Content2), f105, 0;
    }

    &:after {
      @mixin circleBtn;
      background: var(--ContentLight);
      font-size: calc(var(--fontSize) * 1.5);
      height: calc(var(--Spacer) * 1.5);
      line-height: calc(var(--Spacer) * 1.5);
      margin: 0 calc(var(--Spacer) * 0.5);
      width: calc(var(--Spacer) * 1.5);
    }

    &:focus, &:hover {
      &:after {
        background: var(--brandColor);
      }
    }
  }
}