.panel {
  &-head {
    clear: fix;
    color: var(--ContentDark);
    font-weight: bold;
    margin: 0 0 var(--Spacer) 0;

    @media (--screen-600) {
      margin: 0 0 calc(var(--Spacer) * 2) calc(var(--Spacer) * 3.6);
    }

    @mixin fontAwesomeIcon before, var(--brandColor), f128, calc(var(--Spacer) * 0.2);

    &--open {
      &:before {
        color: var(--brandColor);
      }
    }

    &--close {
      &:before {
        color: var(--ContentDark);
      }
    }

    &:before {
      display: block;
      font-weight: normal;
      float: left;
      font-size: calc(var(--fontSize) * 3);
      margin: 0 calc(var(--Spacer) * 0.2) 0 0;

      @media (--screen-600) {
        margin: 0 calc(var(--Spacer) * 0.2) 0 calc(var(--Spacer) * -1.2);
        position: absolute;
      }
    }

    &.faq:before {
      content: '\f129';
    }

    &.news:before {
      content: '\f078';
      font-size: calc(var(--fontSize) * 2);

      @media (--screen-600) {
        margin: 0 calc(var(--Spacer) * 0.2) 0 calc(var(--Spacer) * -1.8);
      }
    }

  }

  &-body {
    color: var(--ContentLight);

    @media (--screen-600) {
      padding: 0 var(--Spacer) var(--Spacer) calc(var(--Spacer) * 3.6);
    }

    &--open {
      display: block;
    }

    &--close {
      display: none;
    }
  }
}

.js-panel {
  .panel-head {
    cursor: pointer;
  }
}