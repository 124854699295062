table {
  border-collapse: collapse;
  color: var(--Content);
  padding: calc(var(--Spacer) * 0.5);
  width: 100%;

  @media (--screen-600) {
    padding: var(--Spacer);
  }

  .tableTitle {
    background: color(var(--ContentLight) l(+30%));
    font-weight: 700;
  }

  th,
  td {
    /* border: 1px solid color(var(--ContentLight) l(+50%)); */
    border: 1px solid color(var(--ContentLight) l(+30%));
    padding: calc(var(--Spacer) * 0.7);
  }

  &.maxtable > tbody > tr > td {
    border: none;
    padding: 0;
    text-align: center;

    p {
      text-align: left;
    }
  }

  &.tbContact,
  &.tbForm {

    th,
    td {
      border: none;
      display: inline-block;
      padding: 0 0 var(--Spacer) 0;

      @media (--screen-600) {
        display: table-cell;
        padding: calc(var(--Spacer) * 0.2) 0;
      }
    }

    .colContact,
    .columnName {
      color: var(--ContentDark);
      font-weight: bold;
      padding: var(--Spacer) 0 0;
      width: 100%;

      @media (--screen-600) {
        padding: 0;
      }
    }
  }

  &.tbContact {

    tr {

      + tr {
        border-top: 1px dotted color(var(--ContentLight) l(+50%));

        @media (--screen-600) {
          border: none;
        }
      }
    }

    td,
    th {
      @media (--screen-600) {
        width: 70%;
      }
    }

    .colContact {
      padding: 1em 0 0;

      @media (--screen-600) {
        padding: 0;
        width: 30%;
      }
    }
  }

  &.tbForm {
    border: none;

    tr {

      + tr {
        border-top: 1px dotted color(var(--ContentLight) l(+50%));
      }
    }

    th {
      display: none;

      @media (--screen-600) {
        display: table-cell;
      }
    }

    .columnName {
      @media (--screen-600) {
        width: 40%;
      }
      @media (--screen-1200) {
        width: 40%;
      }
    }
  }

  &.inquForm {

    td {
      border: none;
      padding-top: var(--Spacer);
    }

    ul {
      margin: 0;
    }

    li {
      display: block;

      +li {
        margin-top: 0;
      }
    }
  }
}