.Sidebar {
  margin-top: calc(var(--Spacer) * 2);
  padding: var(--Spacer) 0;

  @media (--screen-1200) {
    margin-top: 0;
    padding: 0 calc(var(--Spacer) * 5) 0 0;
    lost-column: 2/7 2;
    lost-move: -5/7;
  }

  .Newsin {
    margin: calc(var(--Spacer) * 4) 0;
    padding: 0 var(--Spacer);

    @media (--screen-1200) {
      padding: 0;
    }

    h2:after {
      background: var(--brandColor);
      content: '';
      display: block;
      height: 7px;
      margin: calc(var(--Spacer) * 0.2) 0 var(--Spacer);
      width: 100%;
    }


    li, a {
      display: block;
    }

    li {
      padding: var(--Spacer);

      + li {
        border-top: 1px solid color(var(--Content) l(+50%));
      }
    }

    a {
      color: var(--ContentLight);
      max-height: calc(var(--Spacer) * 4);
      overflow: hidden;

      &:hover, &:focus {
        color: var(--brandColor2);
      }
    }
  }
}

.Side {
  &Box {
    background: var(--brandColor);
    margin-bottom: calc(var(--Spacer) * 5);
    padding: var(--Spacer) var(--Spacer) calc(var(--Spacer) * 1.5);
    text-align: center;

    h6 {
      color: var(--Content2);
      font-size: 2.2em;
    }

    input {
      border: 2px solid var(--Content2);
      border-radius: 0.6em;
      padding: 0.8em;
      width: 90%;

      &:hover, &:focus {
        border: 2px solid var(--brandColor2);
      }

      &[type='submit'] {
        cursor: pointer;
        border: none;
        background: var(--brandColor2);
        color: var(--Content);
        padding: 0.8em;
        margin: 0;
        width: 90%;

        &:hover, &:focus {
          color: var(--Content2);
          background: var(--ContentLight);
        }
      }
    }

    img[src="/SiteStyle/VA/01/images/ico_arrow.gif"] {
      display: none;
    }

    .VipLogin {
      display: block;
      margin-bottom: var(--Spacer);
    }

    a {
      color: var(--Content2);
      display: inline-block;
      padding: 0 calc(var(--Spacer) * 0.5);

      &:hover, &:focus {
        color: var(--brandColor2);
      }
    }
  }
}