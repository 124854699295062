@define-extend shadow {
  filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, 0.5) );
  -webkit-filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, 0.5) );
  text-shadow: 1px 1px 5px #000;
}

@define-extend animate {
  transition: all 250ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

@define-extend alink {
  display: inline-block;
  text-decoration: none;
}

@define-extend fontAwesome {
  font-family: fontAwesome;
}

@define-extend fontDefault {
  font-family: var(--fontFamily);
}

@define-extend formDefault {
  color: var(--Content);
  padding: 1em;
  border: 1px solid color(var(--ContentLight) l(+50%));
  font-family: inherit;
}

@define-extend circleBtn {
  background: var(--brandColor);
  border-radius: calc(var(--Spacer) * 0.3);
  border: none;
  color: var(--Content2);
  cursor: pointer;
  font-size: calc(var(--Spacer) * 1.5);
  height: calc(var(--fontSize) * 3.2);
  line-height: calc(var(--fontSize) * 3.2);
  text-align: center;
  width: calc(var(--fontSize) * 3.2);
  border-radius: 10px;
}