input {
  @mixin formDefault;
  /* padding: 0.7em 1.3em;
  cursor: pointer;
    &:focus, &:hover {
      background: var(--ContentLight);
    } */

  &[type="radio"],
  &[type="checkbox"] {
    background: transparent;
    border: none;
    padding: 0;
  }

  &[type="button"],
  &[type="Submit"],
  &[type="reset"] {
    background: var(--brandColor);
    border: none;
    color: var(--Content2);
    margin: calc(var(--Spacer) * 0.5);
    border-radius: 8px;
    padding: 0.7em 1.3em;
    cursor: pointer;

    &:focus, &:hover {
      background: var(--ContentLight);
      border-color: @background;
    }
  }

  &[type="text"] {
    width: 100%;
    border: 1px solid color(var(--ContentLight) l(+30%));
    border-radius: 8px;
    &:focus, &:hover {
      border-color: var(--brandColor2);
    }
  }

  &[type="checkbox"],
  &.authnumx2 {
    margin-right: var(--Spacer);
    width: auto;
  }
}

textarea {
  @mixin formDefault;

  &.message {
    width: 100%;
    border: 1px solid color(var(--ContentLight) l(+30%));
    border-radius: 8px 8px 0 8px;
    &:focus, &:hover {
      border-color: var(--brandColor2);
    }
  }
}

select {
  @mixin formDefault;

  &[name="Country"] {
    width: 100%;
    border: 1px solid color(var(--ContentLight) l(+30%));
    border-radius: 8px;
    &:focus, &:hover {
      border-color: var(--brandColor2);
    }
  }
}

.InquiryForm {
  h3 {
    color: var(--brandColor2);
    /* font-size: calc(var(--fontSize) * 0.8); */
    font-size: var(--fontSize);
  }
}