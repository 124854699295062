.Footer {
  background: var(--brandColor);
  color: var(--Content2);
  font-size: calc(var(--fontSize) * 0.9);
  text-align: center;

  &__copyright {
    border-bottom: solid 9px var(--brandColor2);

    a {
      color: var(--brandColor2);
      margin: 0 calc(var(--Spacer) * 0.5);

      &:hover, &:focus {
        color: var(--ContentLight);
      }
    }

    .boxFix {
      padding: 0 var(--Spacer) var(--Spacer);

      @media (--screen-1200) {
        padding: 0 calc(var(--Spacer) * 5) calc(var(--Spacer) * 2);
      }
    }
  }

  > .boxFix {
    padding: var(--Spacer) var(--Spacer) 0;

    > div + div {
      margin: var(--Spacer) 0;

      @media (--screen-600) {
        margin: 0;
      }
    }

    @media (--screen-1200) {
      padding: calc(var(--Spacer) * 2) calc(var(--Spacer) * 5) 0;
    }
  }

  a {
    color: var(--brandColor2);
    @extend animate;

    &:hover, &:focus {
      color: var(--ContentLight);
    }
  }

  li {
    display: block;
  }

  h2 {
    border-bottom: 1px solid var(--brandColor2);
    color: var(--Content2);
    font-size: calc(var(--fontSize) * 1.2);
    font-weight: bold;
    padding: calc(var(--Spacer) * 0.5) calc(var(--Spacer) * 0.3);
  }

  ul {
    padding: calc(var(--Spacer) * 0.5);
  }

  .Address {
    @media (--screen-768) {
      .info {
        display: inline-block;
        margin: 0 calc(var(--Spacer) * 2);
      }
    }

    .info {
      display: block;
    }
    .fa {
      margin-right: calc(var(--Spacer) * 0.5);
    }

    .title {
      /* color: color(var(--brandColor2) l(-5)); */
      color: var(--Content2);
      display: inline-block;
      padding: 0;
      /* width: calc(var(--Spacer) * 6); */
    }

  }

}

.gototop {
  @mixin circleBtn;
  display: none;
  font-size: 2em;
  left: auto;
  position: fixed;
  right: calc(var(--Spacer) * 0.6);
  bottom: var(--Spacer);
  /* top: auto; */
  z-index: 1;
  background-color: var(--brandColor2);
  /* border-radius: calc(var(--Spacer) * 0.3); */
  box-shadow: 4px 4px 10px #51515166;

  @media (--screen-1200) {
    right: var(--Spacer);
    bottom: var(--Spacer);
  }

  &:before {
    content: '\f106';
    @extend fontAwesome;
  }
}