.slick {
  display: none;

  &-slider {
    display: block;
    z-index: 1;

    &:hover,
    &:focus {
      .slick-arrow {
        opacity: 0.8;
      }

      .slick-dots {
        opacity: 0.8;
      }
    }


    img {
      width: 100%;
    }
  }

  &-arrow {
    background: none;
    border-radius: 10px;
    font-size: 0;
    margin-top: -1.6rem;
    opacity: 0;
    position: absolute;
    top: 50%;
    z-index: 1;
    @extend animate;
    @mixin circleBtn;

    &:focus, &:hover {
      background-color: var(--brandColor);
    }

    &:before {
      font-size: 2.5rem;
      @extend fontAwesome;
    }
  }

  &-prev {
    left: 1rem;

    &:before {
      content: '\f104';
    }
  }

  &-next {
    right: 1rem;

    &:before {
      content: '\f105';
    }
  }

  &-dots {
    bottom: var(--fontSize);
    display: none !important;
    font-size: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    @extend animate;

    @media (--screen-600) {
      display: block !important;
    }

    li {
      display: inline-block;
      + li {
        margin-left: 0.5rem;
      }
    }

    button {
      @mixin circleBtn;
      font-size: 0;
      height: 10px;
      width: 10px;
    }
  }
}

.Banner {
  margin-bottom: -12px;
  .slick-current {
    animation-name: fadeIn;
    animation-duration: 1s;
  }
}