* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(187, 0, 0, 0.3);
}

:focus {
  outline: 0;
}

::selection {
  background: var(--brandColor);
  color: var(--Content2);
}

html, body {
  overflow-x: hidden;
  width: 100%;
  word-wrap: break-word;
  @extend fontDefault;
}

img {
  border: none;
  max-height: 100%;
  max-width: 100%;
}

.Container {
  padding: calc(var(--Spacer) * 2) 0;

  @media (--screen-600) {
    padding: calc(var(--Spacer) * 5) 0;
  }
}

.clearfix {
  margin-top: calc(var(--Spacer) * 3);
  clear: fix;
}

.boxFix {
  clear: fix;
  margin: 0;
  max-width: 100%;

  @media (--screen-1366) {
    margin: 0 auto;
    width: 1440px;
  }
}