.SideProducts {
  h2 {
    background: var(--brandColor);
    color: var(--Content2);
    text-align: center;
    padding: calc(var(--Spacer) * 0.15);
  }

  .product-menu {

    a {
      padding: 13px 20px;
      /* make room for the toggle button (sub indicator) */
      padding-right: 58px;
      color: var(--ContentLight);

      &:hover,
      &:focus,
      &:active {
        padding: 13px 20px;
        /* make room for the toggle button (sub indicator) */
        padding-right: 58px;
        color: var(--brandColor2);

        span.sub-arrow {
          border-bottom: 2px solid var(--brandColor2);
          border-right: 2px solid var(--brandColor2);
        }
      }

      &.current {
        color: var(--brandColor2);
      }

      &.disabled {
        color: #bbbbbb;
      }

      span.sub-arrow {
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 20px;
        width: 0;
        height: 0;
        background: transparent;
        width: 7px;
        height: 7px;
        border-bottom: 2px solid var(--ContentLight);
        border-right: 2px solid var(--ContentLight);
        transform: rotate(45deg);
        transition: 0.3s;
        &:hover,
        &:focus,
        &:active {
          border-bottom: 2px solid var(--brandColor2);
          border-right: 2px solid var(--brandColor2);
        }
      }


    }

    li {
      border-top: 1px dotted color(var(--ContentLight) l(+50%));
    }

    > li:first-child {
      border-top: 0;
    }

    ul {

      a {
        font-size: 16px;
        border-left: var(--Spacer) solid transparent;

        &:hover, &:focus, &:active {
          font-size: 16px;
          border-left: var(--Spacer) solid transparent;
        }
      }

      ul {

        a {
          border-left: calc(var(--Spacer) * 2) solid transparent;

          &:hover, &:focus, &:active {
            border-left: calc(var(--Spacer) * 2) solid transparent;
          }
        }

        ul {

          a {
            border-left: calc(var(--Spacer) * 3) solid transparent;

            &:hover, &:focus, &:active {
              border-left: calc(var(--Spacer) * 3) solid transparent;
            }
          }

          ul {

            a {
              border-left: calc(var(--Spacer) * 4) solid transparent;

              &:hover, &:focus, &:active {
                border-left: calc(var(--Spacer) * 4) solid transparent;
              }
            }

            ul a {
              border-left: calc(var(--Spacer) * 5) solid transparent;

              &:hover, &:focus, &:active {
                border-left: calc(var(--Spacer) * 5) solid transparent;
              }
            }
          }
        }
      }
    }
  }
}