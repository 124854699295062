.Content .downloadfolder,
.Content .downloadfile {

  h2 {
    font-size: calc(var(--Spacer) * 1.4);
    &:after {
      display: none;
    }
  }

  .dcdesc,
  .fdesc {
    list-style: none;
    margin: 0;
  }
}