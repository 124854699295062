/* :: barPInfo :: */
.barPInfo {
  background: color(var(--brandColor) alpha(-90%));
  padding: var(--Spacer);
  margin: calc(var(--Spacer) * 2) 0;

  a {
    margin: 0 calc(var(--Spacer) * 0.5);
    color: var(--brandColorLight);

    &:hover, &:active {
      color: var(--ContentLight);
      @extend animate;
    }
  }

  input {
    padding: calc(var(--Spacer) * 0.7) calc(var(--Spacer) * 1.3);
    margin: calc(var(--Spacer) * 0.2) calc(var(--Spacer) * 0.5);
    border-radius: calc(var(--Spacer) * 0.5);
  }

  .inquiryBtn {
    background: var(--brandColor2);
  }
}


/* :: boxProduct :: */
.boxProduct {
  border: 2px solid transparent;
  box-shadow: 4px 4px 10px #51515166;
  clear: fix;
  margin-top: calc(var(--Spacer) * 3);
  position: relative;

  &:focus, &:hover {
    border: 2px solid color(var(--brandColor) a(70%));
    @extend animate;
  }

  > a {
    display: block;
    overflow:hidden;
    position:relative;

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }

    @media (--screen-600) {
      lost-column: 1/2 0 0;
    }

    @media (--screen-768) {
      lost-column: 3/10 0 0;
    }

    img {
      bottom: 0;
      height: 100%;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  .PRight {
    background: color(var(--ContentLight) l(+60%));
    padding:
      calc(var(--Spacer) * 1.5) calc(var(--Spacer) * 2)
      calc(var(--Spacer) * 4);

    @media (--screen-600) {
      lost-column: 1/2 0 0;
      min-height: calc(var(--Spacer) * 24);

      &:nth-child(n) {
        float: right;
      }
    }

    @media (--screen-768) {
      lost-column: 7/10 0 0;
    }

    &:before {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;

      @media (--screen-768) {
        bottom: 0;
        left: 30%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    h4 {
      margin-bottom: var(--Spacer);
    }

    h3 {
      color: var(--Content);
      font-size: var(--fontSize);
      line-height: calc(var(--Spacer) * 2);
    }
  }

  .listSeries {
    padding-left: calc(var(--Spacer) * 1.5);

    a {
      color: var(--Content);

      &:focus, &:hover {
        color: var(--brandColor2);
      }
    }
  }

  .PRightNoImg {
    background: color(var(--ContentLight) l(+60%));
  }

  .txtMore {
    bottom: var(--Spacer);
    left: auto;
    position: absolute;
    right: var(--Spacer);
    top: auto;
    z-index: 1;

    a {
      @extend animate;
      @mixin circleBtn;
      float: right;
      font-size: 0;

      @mixin fontAwesomeIcon before, var(--Content2), f067, 0;

      &:before {
        font-size: calc(var(--fontSize) * 1.3);
        line-height: calc(var(--Spacer) * 2.5);
        color: var(--Content2);
      }

    }
  }
}


/* :: blueline :: */
.blueline {
  clear: fix;
}


/* :: blueBg :: */
.blueBg {
  clear: both;
  color: var(--brandColor);
  font-size: calc(var(--fontSize) * 1.5);
  margin: calc(var(--Spacer) * 2) 0 var(--Spacer);
}


/* :: boxProductBom, boxProductBlk :: */
.boxProductBom,
.boxProductBlk {
  lost-column: 1/2;
  text-align: center;

  @media (--screen-768) {
    lost-column: 1/3;
  }

  /* @media (--screen-1200) {
    lost-column: 1/4;
  } */

  &:focus img,
  &:hover img {
    border: 2px solid color(var(--brandColor) a(70%));
  }

  a {
    color: var(--Content);
  }

  h3,
  h4 {
    font-size: var(--fontSize);
    height: calc(var(--Spacer) * 3);
    line-height: calc(var(--Spacer) * 1.5);
    overflow: hidden;
    margin-top: 10px;
  }

  img {
    border: 2px solid transparent;
    box-shadow: 4px 4px 10px #51515166;
    @extend animate;
  }
}


/* :: boxProductBlkR setting :: */
.boxProductBlkR {
  lost-column: 1/2;
  text-align: center;

  @media (--screen-768) {
    lost-column: 1/4;
  }
}


/* :: DM mode setting :: */
h5 + a[style="cursor: HAND;cursor:pointer;"] > img {
  width: 100%; 
}


/* :: pro page setting :: */
.opentable {
  /* @extend animate;
  background: var(--ContentLight);
  color: var(--Content2);
  display: block;
  margin: calc(var(--Spacer) * 3) 0 calc(var(--Spacer) * 0.5);
  padding: calc(var(--Spacer) * 0.5);
  text-align: center;

  &:focus,
  &:hover {
    background: var(--ContentDark);
    color: var(--Content2);
  } */

  @extend animate;
  background: var(--brandColor);
  border: none;
  color: var(--Content2);
  /* margin: calc(var(--Spacer) * 0.5); */
  border-radius: 8px;
  padding: 0.7em 1.3em;

  &:focus, &:hover {
    background: var(--ContentLight);
    color: var(--Content2);
  }
}

.areaTable {
  overflow-x: auto;
}

.RightBox {
  margin-bottom: var(--Spacer);

  @media (--screen-600) {
    float: right;
  }

  .inquiryBtn {
    background: var(--brandColor2);
  }
}

.imgProductDetail {

  @media (--screen-600) {
    max-width: 50%;
  }

  p {
    height: calc(var(--Spacer) * 4);
    overflow: hidden;
  }

  img {
    box-shadow: 4px 4px 10px #51515166;
    width: 100%;
  }

  .btnFunction {
    position: relative;

    a {
      @mixin circleBtn;
      @mixin fontAwesomeIcon before, var(--Content2), f00e, 0;
      bottom: auto;
      display: inline-block;
      left: auto;
      overflow: hidden;
      position: absolute;
      right: calc(var(--Spacer) * 0.5);
      text-indent: -9999px;
      top: calc(var(--Spacer) * -6.5);

      &:before {
        text-indent: 9999px;
      }
    }

    input {
      display: none;
    }
  }
}

.download {

  a {
    background: var(--brandColor);
    border: none;
    color: var(--Content2);
    /* margin: calc(var(--Spacer) * 0.5); */
    border-radius: 8px;
    padding: 0.7em 1.3em;

    &:focus, &:hover {
      @extend animate;
      background: var(--ContentLight);
    }

    /* @mixin fontAwesomeIcon after, var(--Content2), f019, 0; */
  }
}

.gotop {
  display: none;
}