.PhotoWithText {
  clear: fix;

  + .PhotoWithText {
    border-top: 1px dotted color(var(--ContentLight) l(+50%));
    padding-top: calc(var(--Spacer) * 4);
  }

  .textIntro {
    margin-bottom: calc(var(--Spacer) * 2);

    @media (--screen-600){
      lost-column: 3/5;
      margin-bottom: 0;
    }

    div  {
      + div {
        margin-top: calc(var(--Spacer) * 2);
      }

      h3 {
        background: transparent;
        padding: 0;
      }
    }
  }

  .imgIntro {
    @media (--screen-600){
      lost-column: 2/5;
    }
  }

  h3 {
    background: color(var(--brandColor) alpha(-90%));
    color: var(--ContentDark);
    line-height: calc(var(--Spacer) * 2);
    font-size: var(--fontSize);
    font-weight: normal;
    margin-bottom: var(--Spacer);
    padding: var(--Spacer);

    &:empty {
      display: none;
    }
  }

  h4 {
    margin-bottom: var(--Spacer);
  }
}
