@import 'base/variables';

/* :: plugins :: */
@import '../../node_modules/font-awesome/css/font-awesome.css';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/smartmenus/dist/css/sm-core-css.css';
@import '../../node_modules/animate.css/animate.css';

/* :: extend :: */
@import 'mixins/extend';
@import 'mixins/mixin';

/* :: plugins custom setting :: */
@import 'plugins/slick-custom';

/* :: global modules :: */
@import 'modules/global/form';
@import 'modules/global/text';
@import 'modules/global/table';
@import 'modules/global/panel';

/* :: EP modules :: */
@import 'modules/ep/contact';
@import 'modules/ep/download';
@import 'modules/ep/faq';
@import 'modules/ep/HotProducts';
@import 'modules/ep/PageCode';
@import 'modules/ep/PhotoWithText';
@import 'modules/ep/Products';
@import 'modules/ep/SideProduct';

/* :: layout :: */
@import 'layout/base';
@import 'layout/Content';
@import 'layout/Footer';
@import 'layout/Header';
@import 'layout/Navi';
@import 'layout/Sidebar';
@import 'layout/TopNavi';

/* :: responsive :: */
@import 'mixins/breakpoints';